/* eslint-disable no-console */
import { handleError } from 'utils/errorHandler';
import axios from 'config/axios';
import { toast } from 'react-toastify';
import {
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  ADD_ADMIN_REQUEST,
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_SINGLE_ADMIN_SUCCESS,
  GET_BANKS_REQUEST,
  GET_BANKS_SUCCESS,
  GET_BANKS_FAILURE,
  ADD_BANKS_REQUEST,
  ADD_BANKS_FAILURE,
  EDIT_BANKS_REQUEST,
  EDIT_BANKS_FAILURE,
  DELETE_BANKS_REQUEST,
  DELETE_BANKS_FAILURE,
  GET_PENSION_REQUEST,
  GET_PENSION_SUCCESS,
  GET_PENSION_FAILURE,
  ADD_PENSION_REQUEST,
  ADD_PENSION_FAILURE,
  EDIT_PENSION_REQUEST,
  EDIT_PENSION_FAILURE,
  DELETE_PENSION_REQUEST,
  DELETE_PENSION_FAILURE,
  GET_SIGNATURE_REQUEST,
  GET_SIGNATURE_SUCCESS,
  GET_SIGNATURE_FAILURE,
  ADD_SIGNATURE_REQUEST,
  ADD_SIGNATURE_FAILURE,
  EDIT_SIGNATURE_REQUEST,
  EDIT_SIGNATURE_FAILURE,
  DELETE_SIGNATURE_REQUEST,
  DELETE_SIGNATURE_FAILURE,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_ERROR,
  GET_ALL_USER_SUCCESS,
} from '../actionTypes/settings';








import {
  addAdminApi,
  getAdminApi,
  getSingleAdminApi,
  getAdminSearchApi,
  editAdminApi,
  deleteAdminApi,
} from '../api/settings';


export const addAdmins = (payload, paginate = '', page = 1, pagesize = 10) => async (dispatch) => {
  dispatch({
    type: ADD_ADMIN_REQUEST,
  });

  try {
    await addAdminApi(payload);
    dispatch({
      type: ADD_ADMIN_SUCCESS,
    });
    const response = await getAdminApi(paginate, page, pagesize);
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

export const getAdmin = (paginate = '', page = 1, pagesize = 10) => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_REQUEST,
  });
  try {
    const response = await getAdminApi(paginate, page, pagesize);
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

export const getSingleAdmin = (id) => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_REQUEST,
  });

  try {
    const response = await getSingleAdminApi(id);
    dispatch({
      type: GET_SINGLE_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

export const getAdminSearch = (search) => async (dispatch) => {
  dispatch({
    type: GET_ADMIN_REQUEST,
  });
  try {
    const response = await getAdminSearchApi(search);
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

export const editAdmins = (user, payload, paginate = '', page = 1, pagesize = 10) => async (dispatch) => {
  dispatch({
    type: ADD_ADMIN_REQUEST,
  });

  try {
    await editAdminApi(user, payload);
    dispatch({
      type: ADD_ADMIN_SUCCESS,
    });
    const response = await getAdminApi(paginate, page, pagesize);
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

export const deleteAdmins = (user, paginate = '', page = 1, pagesize = 10) => async (dispatch) => {
  dispatch({
    type: ADD_ADMIN_REQUEST,
  });

  try {
    await deleteAdminApi(user);
    dispatch({
      type: ADD_ADMIN_SUCCESS,
    });
    const response = await getAdminApi(paginate, page, pagesize);
    dispatch({
      type: GET_ADMIN_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: ADD_ADMIN_FAILURE,
      payload: errMsg,
    });
  }
};

// refactor later
export const getBanks = (url = '/setting/bank/?page_size=1000') => async (dispatch) => {
  try {
    dispatch({ type: GET_BANKS_REQUEST });
    const result = await axios.get(url);
    dispatch({ type: GET_BANKS_SUCCESS, payload: result.data || [] });
  } catch (error) {
    dispatch({ type: GET_BANKS_FAILURE });
    toast.error('failed to fetch banks');
  }
};

export const addBank = (bank) => async (dispatch) => {
  try {
    dispatch({ type: ADD_BANKS_REQUEST });
    await axios.post('/setting/bank/', bank);
    dispatch(getBanks());
  } catch (error) {
    dispatch({ type: ADD_BANKS_FAILURE });
    console.error(error);
    toast.error('failed to save bank');
  }
};

export const editBank = (bankId, newBank) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_BANKS_REQUEST });
    await axios.patch(`/setting/bank/${bankId}/`, newBank);
    dispatch(getBanks());
  } catch (error) {
    dispatch({ type: EDIT_BANKS_FAILURE });
    console.error(error);
    toast.error('failed to edit bank');
  }
};

export const deleteBank = (bankId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BANKS_REQUEST });
    await axios.delete(`/setting/bank/${bankId}`);
    dispatch(getBanks());
  } catch (error) {
    dispatch({ type: DELETE_BANKS_FAILURE });
    toast.error('failed to fetch banks');
  }
};

export const getSignature = (url = '/setting/signature/') => async (dispatch) => {
  try {
    dispatch({ type: GET_SIGNATURE_REQUEST });
    const result = await axios.get(url);
    dispatch({ type: GET_SIGNATURE_SUCCESS, payload: result.data || [] });
  } catch (error) {
    dispatch({ type: GET_SIGNATURE_FAILURE });
    toast.error('failed to fetch signature');
  }
};

export const addSignature = (signature) => async (dispatch) => {
  try {
    dispatch({ type: ADD_SIGNATURE_REQUEST });
    await axios.post('/setting/signature/', signature);
    dispatch(getSignature());
  } catch (error) {
    dispatch({ type: ADD_SIGNATURE_FAILURE });
    console.error(error);
    toast.error('failed to save signature');
  }
};

export const editSignature = (signatureId, newSignature) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_SIGNATURE_REQUEST });
    await axios.patch(`/setting/signature/${signatureId}/`, newSignature);
    dispatch(getSignature());
  } catch (error) {
    dispatch({ type: EDIT_SIGNATURE_FAILURE });
    console.error(error);
    toast.error('failed to edit signature');
  }
};

export const deleteSignature = (signatureId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SIGNATURE_REQUEST });
    await axios.delete(`/setting/signature/${signatureId}`);
    dispatch(getSignature());
  } catch (error) {
    dispatch({ type: DELETE_SIGNATURE_FAILURE });
    toast.error('failed to delete signature');
  }
};

export const getPensionManagers = (url = '/setting/pfa/') => async (dispatch) => {
  try {
    dispatch({ type: GET_PENSION_REQUEST });
    const result = await axios.get(url);
    dispatch({ type: GET_PENSION_SUCCESS, payload: result.data });
  } catch (error) {
    dispatch({ type: GET_PENSION_FAILURE });
    toast.error('failed to fetch pfa data');
  }
};

export const addPfa = (pfa) => async (dispatch) => {
  try {
    dispatch({ type: ADD_PENSION_REQUEST });
    await axios.post('/setting/pfa/', pfa);
    dispatch(getPensionManagers());
  } catch (error) {
    dispatch({ type: ADD_PENSION_FAILURE });
    console.error(error);
    toast.error('failed to save pfa');
  }
};

export const editPfa = (pfaId, newPfa) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_PENSION_REQUEST });
    await axios.patch(`/setting/pfa/${pfaId}/`, newPfa);
    dispatch(getPensionManagers());
  } catch (error) {
    dispatch({ type: EDIT_PENSION_FAILURE });
    console.error(error);
    toast.error('failed to edit pfa');
  }
};

export const deletePfa = (pfaId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PENSION_REQUEST });
    axios.delete(`/setting/pfa/${pfaId}`);
    dispatch(getPensionManagers());
  } catch (error) {
    dispatch({ type: DELETE_PENSION_FAILURE });
    toast.error('failed to fetch pfa');
  }
};




export const getBankSearch=(search='') => async () => {
  try {
  
    const response = await axios.get(`/setting/bank?search=${search}`);
    return response.data

  } catch (error) {
    
    toast.error(
      error?.response?.data?.errors ||
        error?.response?.data?.message ||
        'failed to get deparment data'
    );
  }
};


export const getPensionManagerSearch = (search='') => async (dispatch) => {
  try {
    const response = await axios.get(`/setting/pfa/?search=${search}`);
    return response.data
  } catch (error) {
    toast.error(
      error?.response?.data?.errors ||
        error?.response?.data?.message ||
        'failed to get deparment data'
    );
  }
};



export const getAllUsersList = (paginate = '', page = 1, pagesize = 10) => async (dispatch) => {
  dispatch({
    type:GET_ALL_USER_REQUEST,
  });
  try {
    const response = await axios.get(`/auth/users/?roles=EMPLOYEE,ADMIN&page=${page}&pagesize=${pagesize}`);
    dispatch({
      type: GET_ALL_USER_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: GET_ALL_USER_ERROR,
      payload: errMsg,
    });
  }
};

export const getAllUsersListSearch = (search) => async (dispatch) => {
  dispatch({
    type:GET_ALL_USER_REQUEST,
  });
  try {
   
    const response = await axios.get(`/auth/users/?roles=EMPLOYEE,ADMIN&search=${search}`);
    dispatch({
      type: GET_ALL_USER_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    const errMsg = handleError(err);
    dispatch({
      type: GET_ALL_USER_ERROR,
      payload: errMsg,
    });
  }
};




export const getImpersonateUser = (data) => {
  return async (dispatch) => {
   return await axios.post(`/auth/users/impersonate/`, data);
   };
};



